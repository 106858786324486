<template>
    <b-card>
        <b-row>
            <b-col cols="9">
                <b-button class="m-1 " v-ripple.400="'#1396EB'" variant="primary" v-b-modal.modal-primaryadd>
                    Добавление
                </b-button>
            </b-col>
            <b-col cols="2">
                <b-form-select class="m-1" v-model="defaultLang" :options="langs" text-field="name" value-field="code"
                    @change="getItems" />
            </b-col>
        </b-row>
        <b-modal @ok="add" @hidden="resetModal" id="modal-primaryadd" ok-only ok-title="Добавить"
            modal-class="modal-primary" centered title="Добавление">
            <b-row>
                <b-col cols="12">
                    <b-form-group label="Название" label-for="Title">
                        <b-form-input v-model="pro.name" id="Title" placeholder="Название" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="input-file" class="text-nowrap">Изображение:</label>
                        <b-form-file v-model="pro.img" id="input-file" placeholder="Добавьте изображение..."
                            drop-placeholder="Drop file here..." />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <label for="icon" class="text-nowrap">Иконка:</label>
                        <b-form-file id="icon" v-model="pro.icon" placeholder="Добавьте иконку..."
                            drop-placeholder="Drop file here..." />
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group>
                        <label for="textarea-default" class="text-nowrap">Описание</label>
                        <b-form-textarea v-model="pro.description" id="textarea-default"
                            placeholder="Добавьте описание" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Цена" label-for="price">
                        <b-form-input v-model="pro.price" type="number" id="price" placeholder="Цена" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Ссылка" label-for="link">
                        <b-form-input v-model="pro.link" id="link" placeholder="Ссылка" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Язык" label-for="langs">
                        <b-form-select v-model="pro.lang" :options="langs" text-field="name" value-field="id" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Категория процедуры" label-for="category">
                        <b-form-select v-model="pro.procedure_category_id" :options="categoryPro" text-field="name"
                            value-field="id" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
        <b-table :fields="tableColumns" responsive="sm" :items="proRoom">
            <template #cell(img)="data">
                <b-avatar v-if="data.item.img" class="mr-1" :src="data.item.img" />
            </template>
            <template #cell(icon)="data">
                <b-avatar v-if="data.item.icon" class="mr-1" :src="data.item.icon" />
            </template>
            <template #cell(actions)="data">
                <div class="text-nowrap">
                    <b-button class="btn-icon mr-1" variant="gradient-primary" id="gradient-primaryy"
                        @click="edit(data.item)" v-b-modal.modal-primaryedit>
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button class="btn-icon" variant="gradient-danger" @click="confirmDelete(data.item)">
                        <feather-icon icon="TrashIcon" />
                    </b-button>
                </div>
                <b-modal @hidden="resetModal" @ok="handleEdit" id="modal-primaryedit" ok-only ok-title="Редактировать"
                    modal-class="modal-primary" centered title="Редактирование">
                    <b-row>
                        <b-col cols="12">
                            <b-form-group label="Название" label-for="Title">
                                <b-form-input v-model="pro.name" id="Title" placeholder="Название" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group>
                                <label for="input-file" class="text-nowrap">Изображение:</label>
                                <b-form-file v-model="pro.img" id="input-file" placeholder="Добавьте изображение..."
                                    drop-placeholder="Drop file here..." />
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group>
                                <label for="icon" class="text-nowrap">Иконка:</label>
                                <b-form-file id="icon" v-model="pro.icon" placeholder="Добавьте иконку..."
                                    drop-placeholder="Drop file here..." />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group>
                                <label for="textarea-default" class="text-nowrap">Описание</label>
                                <b-form-textarea v-model="pro.description" id="textarea-default"
                                    placeholder="Добавьте описание" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Цена" label-for="price">
                                <b-form-input v-model="pro.price" type="number" id="price" placeholder="Цена" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Ссылка" label-for="link">
                                <b-form-input v-model="pro.link" id="link" placeholder="Ссылка" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Язык" label-for="langs">
                                <b-form-select v-model="pro.lang" :options="langs" text-field="name" value-field="id" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Категория процедуры" label-for="category">
                                <b-form-select v-model="pro.procedure_category_id" :options="categoryPro"
                                    text-field="name" value-field="id" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-modal>
            </template>
        </b-table>
    </b-card>
</template>
  
<script>
import { BAvatar, BFormFile, BFormSelect, BFormTextarea, BFormInput, BRow, BCol, BFormGroup, BModal, VBModal, BButton, BCard, BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { $themeConfig } from '@themeConfig'


export default {
    components: {
        BAvatar,
        ToastificationContent,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BFormInput,
        BRow,
        BCol,
        BFormGroup,
        BModal,
        VBModal,
        BCard,
        BButton,
        BTable,
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    data() {

        return {
            categoryPro: [],
            defaultLang: 'ru',
            langs: [],
            pro: {
                name: '',
                link: '',
                price: '',
                description: '',
                img: [],
                icon: [],
                lang: '',
                procedure_category_id: ""
            },
            proRoom: [],
            tableColumns: [
                {
                    key: 'id',
                    label: '#',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Название',
                    sortable: true,
                },
                {
                    key: 'img',
                    label: 'Изображение',
                    sortable: true,
                },
                {
                    key: 'icon',
                    label: 'Иконка',
                    sortable: true,
                },
                // {
                //     key: 'procedure_category_id',
                //     label: 'Описание',
                //     sortable: true,
                // },
                {
                    key: 'link',
                    label: 'Ссылка',
                    sortable: true,
                },
                {
                    key: 'price',
                    label: 'Цена',
                    sortable: true,
                },
                {
                    key: 'procedure_category_id',
                    label: 'категория',
                    sortable: true,
                },

                {
                    key: 'actions',
                    label: 'Действия',
                }]
        }
    },
    mounted() {
        this.getProRoom()
        this.getLangs()
        this.getProcedureCategory()
    },

    methods: {
        getItems() {
            this.getProRoom()
            this.getProcedureCategory()
        },
        getProcedureCategory() {

            const getProcedureCategories = {
                method: 'get',
                url: `${$themeConfig.app.API}procedure-category`,
                headers: {
                    "Accept-Language": this.defaultLang
                }
            };
            axios(getProcedureCategories)

                .then(res => {
                    this.categoryPro = res.data.data
                })
                .catch(er => {
                    console.log(er)
                })
        },
        getLangs() {
            axios.get(`${$themeConfig.app.API}langs`)
                .then(res => {
                    this.langs = res.data.data
                })
                .catch(er => {
                    console.log(er)
                })
        },

        getProRoom() {
            const getProRoom = {
                method: 'get',
                url: `${$themeConfig.app.API}procedure?per_page=100`,
                headers: {
                    "Accept-Language": this.defaultLang
                }
            };
            axios(getProRoom)

                .then(res => {
                    this.proRoom = res.data.data
                })
                .catch(er => {
                    console.log(er)
                })
        },
        handleOk() {
            this.handleEdit()
        },
        async handleEdit() {
            const myFormData = new FormData()
            myFormData.append('name', this.pro.name)
            myFormData.append('img', this.pro.img)
            myFormData.append('icon', this.pro.icon)
            myFormData.append('description', this.pro.description)
            myFormData.append('price', this.pro.price)
            myFormData.append('lang_id', this.pro.lang)
            myFormData.append('link', this.pro.link)
            myFormData.append('procedure_category_id', this.pro.procedure_category_id)
            myFormData.append('_method', 'PUT')
            if (this.pro.id !== '') {
                await axios({
                    method: 'post',
                    url: `${$themeConfig.app.API}procedure/${this.pro.id}`,
                    data: myFormData,
                    config: {},
                })
                    .then(() => {
                        this.getProRoom()
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Успешно',
                                text: 'Данные были изменены',
                                icon: 'CheckSquareIcon',
                                variant: 'success',
                            },
                        })
                    })
                    .catch(e => {
                        console.log(e)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Ошибка',
                                text: e.message,
                                icon: 'CheckSquareIcon',
                                variant: 'danger',
                            },
                        })
                    })
            }
        },
        edit(data) {
            this.pro.name = data.name
            this.pro.id = data.id
            this.pro.lang = data.lang.id
            this.pro.img = data.img
            this.pro.description = data.description
            this.pro.price = data.price
            this.pro.procedure_category_id = data.procedure_category.id
            this.pro.icon = data.icon
            this.pro.link = data.link
        },
        async add() {
            const myFormData = new FormData()
            myFormData.append('name', this.pro.name)
            myFormData.append('img', this.pro.img)
            myFormData.append('icon', this.pro.icon)
            myFormData.append('description', this.pro.description)
            myFormData.append('price', this.pro.price)
            myFormData.append('lang_id', this.pro.lang)
            myFormData.append('link', this.pro.link)
            myFormData.append('procedure_category_id', this.pro.procedure_category_id)


            await axios({
                method: 'post',
                url: `${$themeConfig.app.API}procedure`,
                data: myFormData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(() => {
                    this.addStatus = true
                    this.getProRoom()
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Успешно',
                            text: 'Запись успешно добавлен',
                            icon: 'CheckSquareIcon',
                            variant: 'success',
                        },
                    })
                })
                .catch(e => {
                    this.addStatus = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Ошибка',
                            text: e.message,
                            icon: 'CheckSquareIcon',
                            variant: 'danger',
                        },
                    })
                })
        },
        resetModal() {
            this.pro.name = ''
            this.pro.id = ''
            this.pro.lang = ''
            this.pro.img = ''
            this.pro.description = ''
            this.pro.icon = ''
            this.pro.procedure_category_id = ''
            this.pro.price = ''
            this.pro.link = ''
        },
        confirmDelete(data) {
            this.$bvModal
                .msgBoxConfirm('После удаления данные восстановить нельзя будет!', {
                    title: 'Вы уверены что хотите удалить?',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отменить',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        axios.delete(`${$themeConfig.app.API}procedure/${data.id}`)
                            .then(() => {
                                this.getProRoom()
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Успешно',
                                        text: 'Данные были удалены',
                                        icon: 'CheckSquareIcon',
                                        variant: 'success',
                                    },
                                })
                            })
                            .catch(e => {
                                console.log(e)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Ошибка',
                                        text: e.message,
                                        icon: 'CheckSquareIcon',
                                        variant: 'dander',
                                    },
                                })
                            })
                    }
                })
        },
    },


}
</script>